import React, { Component } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

export default class productTemp extends Component {
  render() {
    const data = this.props.data.contentfulProducts;

    return (
      <Layout>
        <SEO
          title={data.title}
          keywords={[
            `WTW `,
            `WTW Analytics`,
            `wtwanalytics.de`,
            `wtwanalytics`,
            `WTW de`,
            `Wastewater treatment`,
            `systems lab`,
            `water analysis`,
            ` quality online measurement technology`,
            `water analysis WTW`,
            `Lab laboratory`,
            `lab`,
            `On-Line`,
            `Online Process`,
            `IDS Wireless`,
            `IQSN`,
            `IQ SENSOR NET`,
            `Photometer`,
            `OxiTop`,
            `MultiLine`,
            `inoLab`,
            `photoLab `,
            `${data.title}`,
          ]}
        />
        <div className="site-container blog-post container">
          <h1 className="title text-center my-5">{data.title}</h1>

          <div className="row">
            <div className="col-lg-6 text-center">
              {data.p1FeatureImage ? (
                <Img
                  className="feature-img"
                  fluid={data.p1FeatureImage.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              ) : (
                <div className="no-image"></div>
              )}
              <Link
                to="/#Contact"
                className="btn px-5 py-3 text-white mt-5"
                style={{ borderRadius: "30px", backgroundColor: "#0085AD" }}
              >
                Get a Quote
              </Link>
            </div>
            <div className="col-lg-6">
              <div className="details">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.p1Description.childMarkdownRemark.html,
                  }}
                />
              </div>
            </div>
          </div>
          {data.product2 ? (
            <div className="row">
              <div className="col-lg-6 text-center">
                <Img
                  className="feature-img"
                  fluid={data.p2FeatureImage.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
                <Link
                  to="/#Contact"
                  className="btn px-5 py-3 text-white mt-5"
                  style={{ borderRadius: "30px", backgroundColor: "#0085AD" }}
                >
                  Get a Quote
                </Link>
              </div>
              <div className="col-lg-6">
                <div className="details">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.p2Description.childMarkdownRemark.html,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="no-image"></div>
          )}
          {data.product3 ? (
            <div className="row">
              <div className="col-lg-6 text-center">
                <Img
                  className="feature-img"
                  fluid={data.p3FeatureImage.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
                <Link
                  to="/#Contact"
                  className="btn px-5 py-3 text-white mt-5"
                  style={{ borderRadius: "30px", backgroundColor: "#0085AD" }}
                >
                  Get a Quote
                </Link>
              </div>
              <div className="col-lg-6">
                <div className="details">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.p3Description.childMarkdownRemark.html,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="no-image"></div>
          )}
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query SinglePostQuery($slug: String!) {
    contentfulProducts(slug: { eq: $slug }) {
      id
      title
      slug
      product2
      product3
      p1FeatureImage {
        fluid(maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      p1Description {
        childMarkdownRemark {
          html
        }
      }
      p2FeatureImage {
        fluid(maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }

      p2Description {
        childMarkdownRemark {
          html
        }
      }
      p3FeatureImage {
        fluid(maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      p3Description {
        childMarkdownRemark {
          html
        }
      }
      createdAt
    }
  }
`;
